import { useState, useEffect, useRef } from 'react';
import WaterDroplet from './WaterDroplet';

const PowerSlider = ({ value, onChange }) => {
  const validValues = [32768, 16384, 8192, 4096, 2048, 1024, 512, 256, 128, 64, 32];
  const min = validValues[0];
  const max = validValues[validValues.length - 1];
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      const updateWidth = () => {
        setContainerWidth(containerRef.current.offsetWidth);
      };      
      updateWidth();
      window.addEventListener('resize', updateWidth);      
      return () => window.removeEventListener('resize', updateWidth);
    }
  }, []);

  const getPosition = (val) => {
    return ((Math.log2(val) - Math.log2(min)) / (Math.log2(max) - Math.log2(min))) * 100;
  };

  const findNearestValue = (clickPercentage) => {
    // Convert each valid value to its position percentage
    const valuePositions = validValues.map(val => ({
      value: val,
      position: getPosition(val)
    }));

    // Find the closest position to where the user clicked
    return valuePositions.reduce((closest, current) => {
      const currentDist = Math.abs(current.position - clickPercentage);
      const closestDist = Math.abs(closest.position - clickPercentage);
      return currentDist < closestDist ? current : closest;
    }).value;
  };

  const handleClick = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const clickPosition = e.clientX - rect.left;
    const clickPercentage = (clickPosition / rect.width) * 100;
    
    // Find the nearest value based on click position percentage
    const nearestValue = findNearestValue(clickPercentage);
    onChange(nearestValue);
  };

  const handleKeyDown = (e) => {
    const currentIndex = validValues.indexOf(value);
    let newIndex = currentIndex;

    switch (e.key) {
      case 'ArrowRight':
      case 'ArrowUp':
        newIndex = Math.min(currentIndex + 1, validValues.length - 1);
        break;
      case 'ArrowLeft':
      case 'ArrowDown':
        newIndex = Math.max(currentIndex - 1, 0);
        break;
      case 'Home':
        newIndex = 0;
        break;
      case 'End':
        newIndex = validValues.length - 1;
        break;
      default:
        return;
    }

    if (newIndex !== currentIndex) {
      e.preventDefault();
      onChange(validValues[newIndex]);
    }
  };

  return (
      <div className="w-full max-w-2xl px-4 py-6 -mt-0.5 ui-widget-content" ref={containerRef}>
      <div className="mb-2 text-sm font-medium">Fast Fourier transform(fft)</div>
      <div className="mb-2 text-xs font-medium">Selected: {value.toLocaleString()}</div>      
      <div 
        className="relative h-8 w-full cursor-pointer"
        onClick={handleClick}
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        tabIndex={0}
        onKeyDown={handleKeyDown}
      >
        {/* Track */}
        <div className="absolute top-1/2 w-full h-1 -mt-0.5 bg-gray-200 rounded-full" />
        
        {/* Markers */}
        {validValues.map((val) => (
          <div
            key={val}
            className="absolute top-0 -translate-x-1"
            style={{ left: `${getPosition(val)}%` }}
          >
            <div className="w-1 h-3 bg-gray-400 ui-widget-content" />
            {/* <div className="mt-1 text-xs text-gray-600 transform -rotate-45 origin-top-left ui-widget-content">
              {val.toLocaleString()}
            </div> */}
            <WaterDroplet><p className="text-xs">{val.toLocaleString()}</p></WaterDroplet>
          </div>
        ))}
        
        {/* Current Value Indicator */}
        <div 
          className="absolute top-0 w-2 h-6 bg-blue-500 -translate-x-1/2 rounded transition-all duration-150"
          style={{ left: `${getPosition(value)}%` }}
        />
      </div>
    </div>
  );
};

export default PowerSlider;