import React, { useState } from "react";
import AIhistoryLogo from "./images/damnit-tameit-ai-history.jpg";
export default function DamnItTameItAIHistory() {
  const [collapse, setCollapse] = useState(true);  
  const [showImage, setShowImage] = useState(false);
  const handleClick = () => {
    setCollapse(!collapse);
  };
  const [expandedDivId] = useState(null);

  return (
    <div
      className="
    w-full 
    max-w-[97%] 
    mx-auto 
    h-auto 
    items-center 
    bg-white 
    dark:bg-gray-600 
    dark:text-white 
    rounded-lg 
    overflow-hidden 
    shadow-2xl 
    hover:shadow-lg 
    dark:hover:shadow-slate-50 
    ui-widget-content 
    content-center
    p-2 
    sm:p-4 
    flex 
    flex-col 
    sm:flex-row 
    space-y-2 
    sm:space-y-0 
    sm:space-x-4
    sm:items-center" onClick={handleClick}>
      <div className="justify-items-start ui-widget-content content-center align-middle"  
          onMouseEnter={() => setShowImage(true)}
          onMouseLeave={() => setShowImage(false)}>        
           {( <img 
            className="w-48 h-auto"
            src={AIhistoryLogo}
            alt="Damn it tame it AI's history"
          /> )}
          <b className="text-left text-nowrap">History</b> 
      </div>
      <div
          className={`dark:bg-gray-600 dark:text-white border-none w-${
            expandedDivId === "div1" ? "full" : "3/4"
          }  ${collapse ? "collapsed" : ""}`}
        >     
      <div className="dark:bg-gray-600 dark:text-white">        
      <div className="h-7 w-auto">&nbsp;</div> 
     <div className="text-justify p-0.5 text-xs">
        <br/>
        <p>
          <b>A brief history:&nbsp;&nbsp;&nbsp;</b><br/>Recent breakthroughs
          in AI, rather recently revealed AI's capabilities to the common world
          is something to think about in the angles of how to leverage AI, ML,
          DL, and its algorithms effectively and gained from, instead of being
          afraid of it. Damn it tame it AI will help Organizations start to use
          and tame AI within their infrastructures.<br/>
          Having worked in the Software Industry consulting for Fortune Companies 100%, 
          Kumar(Muthukumaran) Azhagesan is thoroughly experienced in various software technologies, 
          and has been known for top quality enterprise integration, architecture, design, and
          development using Java 17 to 8, spring boot, SAP, iOS Swift, and almost all trendy
          web(Javascript, JQuery/JQueryUI, Angular, HTML5, CSS3, and recently in React.js) technologies and APIs. Kumar is working in the United
          States for more than 2 decades(i.e. 21+ years). {" "}
        </p>
        <br/>
        <p>
          <b>Where we are today:&nbsp;&nbsp;&nbsp;</b>Read/watch the list of
          AI/ML Projects that were already completed, and the ongoing projects
          showcased by clicking on our project of interest. 
          Available to implement AI & Machine Learning applications for staying ahead in your business.
        </p>{" "}
        <br />
      </div> 
    </div>
    </div>
    </div>
  );
}