import React from 'react';

const WaterDroplet = ({ children }) => {
  return (
    <div className="relative group">
      <div className="relative rounded-full p-1 bg-cyan-100/30 backdrop-blur-sm 
        before:content-[''] before:absolute before:inset-0 before:rounded-full before:bg-gradient-to-b 
        before:from-cyan-200/50 before:to-transparent before:animate-pulse
        after:content-[''] after:absolute after:inset-1 after:rounded-full after:bg-gradient-to-tl 
        after:from-white/60 after:via-transparent after:to-transparent
        shadow-lg hover:shadow-cyan-200/50 transition-all duration-300
        animate-[float_3s_ease-in-out_infinite]">
        <div className="relative z-10">
          {children}
        </div>
      </div>
      <style jsx>{`
        /* @keyframes float {
          0%, 100% {
            transform: translateY(0) rotate(-1deg);
          }
          50% {
            transform: translateY(-10px) rotate(2deg);
          }
        } */
        
        .group:hover::before {
          content: '';
          position: absolute;
          inset: -8px;
          background: radial-gradient(circle at 25% 25%, 
            rgba(103, 232, 249, 0.1), 
            transparent 70%);
          border-radius: 499px;
          z-index: -1;
          animation: ripple 2s linear infinite;
        }
        
        @keyframes ripple {
          0% {
            transform: scale(0.5);
            opacity: 1;
          }
          100% {
            transform: scale(0.75);
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default WaterDroplet;