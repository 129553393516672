import React, { useState } from "react";
import theAIMusicPioneersLogo from './images/The-Music-AI-Pioneers-Logo.png';
export default function MusicAIPioneers() {
  const [collapse, setCollapse] = useState(true);  
  const [showImage, setShowImage] = useState(false);
  const handleClick = () => {
    setCollapse(!collapse);
  };
  const [expandedDivId] = useState(null);

  return (
    <div
      className="
    w-full 
    max-w-[97%] 
    mx-auto 
    h-auto 
    items-center 
    bg-white 
    dark:bg-gray-600 
    dark:text-white 
    rounded-lg 
    overflow-hidden 
    shadow-2xl 
    hover:shadow-lg 
    dark:hover:shadow-slate-50 
    ui-widget-content 
    content-center
    p-2 
    sm:p-4 
    flex 
    flex-col 
    sm:flex-row 
    space-y-2 
    sm:space-y-0 
    sm:space-x-4
    sm:items-center" onClick={handleClick}>
      <div className="justify-items-start ui-widget-content content-center align-middle"  
          onMouseEnter={() => setShowImage(true)}
          onMouseLeave={() => setShowImage(false)}>        
           {( <img 
            className="w-48 h-auto"
            src={theAIMusicPioneersLogo}
            alt="AI Music Pioneers"
          /> )}
          <b className="text-left text-nowrap">AI Music Pioneers</b> 
      </div>
      <div
          className={`dark:bg-gray-600 dark:text-white border-none w-${
            expandedDivId === "div1" ? "full" : "3/4"
          }  ${collapse ? "collapsed" : ""}`}
        >     
      <div className="dark:bg-gray-600 dark:text-white">
          <div className="h-7 w-auto">&nbsp;</div>
         <div className="text-justify p-0.5 text-xs">
          <br/>
          <p>
            <b>Objective:&nbsp;&nbsp;&nbsp;</b>Make music leveraging state of the art AI machine learning deep learning tech in a manner the web users desire.{" "}
          </p><br />
          <p>
            <b>About the project:&nbsp;&nbsp;&nbsp;</b>Leveraging AI, ML, DL to
            produce entertainment in a "You create your own music" way. A team of talented and Enterprise Architects and Software Developers &
            friends @work. Muthukumaran Azhagesan & Rajesh S. Work in progress.
          </p>{" "}<br />
          <p>
            <b>Functionalities:&nbsp;&nbsp;&nbsp;</b>Project planning in progress,
            confidential{" "}
          </p>
          <br/>
        <b>Samples:&nbsp;&nbsp;&nbsp;</b> can be seen by clicking on music player button or menu item in this website or our project image seen above. Note: Leveraged Google's Music AI ML API and recently Facebook/Meta's AI ML API for these music generation.
      </div>
    </div>
    </div>
    </div>
  );
}