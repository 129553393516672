import React, { forwardRef } from 'react';

const ConvexGlassCanvas = forwardRef(({ 
  width = 175, 
  height = 35.5,
  glassOpacity = 0.15,
  blurAmount = 16,
  convexStrength = 0.8, // Controls the intensity of the convex effect (0-1)
  className = "",
  style = {},
  ...props 
}, ref) => {

  return (
    <div className="relative group">
      {/* Convex shadow effect */}
      <div 
        className="absolute -inset-1 rounded-xl bg-black/20 blur-md transition-all duration-300 group-hover:blur-xl"
        style={{
          transform: 'translateZ(-10px)',
        }}
      />

      {/* Main container for convex effects */}
      <div className="relative">
        {/* Edge highlight for 3D effect */}
        <div 
          className="absolute -inset-[1px] rounded-lg"
          style={{
            background: `
              radial-gradient(
                circle at 50% 50%,
                rgba(255,255,255,0.4),
                transparent 70%
              )
            `,
            filter: 'blur(1px)',
          }}
        />

        {/* Convex lighting effect */}
        <div 
          className="absolute inset-0 rounded-lg"
          style={{
            background: `
              radial-gradient(
                circle at 50% 0%,
                rgba(255,255,255,${0.4 * convexStrength}),
                transparent 75%
              ),
              radial-gradient(
                circle at 50% 100%,
                rgba(0,0,0,${0.2 * convexStrength}),
                transparent 75%
              )
            `,
            opacity: 0.9,
          }}
        />

        {/* Main canvas with enhanced convex glass effect */}
        <canvas 
          ref={ref}
          className={`
            relative
            backdrop-blur-xl
            border
            border-white/30
            rounded-lg
            transition-all
            duration-300
            ease-in-out
            group-hover:scale-[1.02]
            ${className}
          `}
          style={{ 
            width: `${width}px`, 
            height: `${height}px`, 
            backgroundColor: `rgba(255, 255, 255, ${glassOpacity})`,
            backdropFilter: `blur(${blurAmount}px)`,
            WebkitBackdropFilter: `blur(${blurAmount}px)`,
            boxShadow: `
              0 0 30px rgba(255,255,255,0.15),
              0 8px 32px 0 rgba(31, 38, 135, 0.37),
              inset 0 2px 4px 0 rgba(255,255,255,0.4),
              inset 0 -2px 4px 0 rgba(0,0,0,0.1),
              inset 0 0 60px rgba(255,255,255,${0.2 * convexStrength})
            `,
            transform: 'translateZ(0)',
            ...style
          }}
          {...props}
        />

        {/* Convex highlight effects */}
        <div 
          className="pointer-events-none absolute inset-0 rounded-lg opacity-60"
          style={{
            background: `
              linear-gradient(
                135deg,
                transparent 0%,
                rgba(255,255,255,${0.25 * convexStrength}) 50%,
                transparent 100%
              ),
              radial-gradient(
                ellipse at 50% 40%,
                rgba(255,255,255,${0.3 * convexStrength}),
                transparent 70%
              )
            `,
          }}
        />

        {/* Dynamic reflection effect */}
        <div 
          className="absolute inset-0 rounded-lg opacity-30 transition-transform duration-300 group-hover:translate-x-1"
          style={{
            background: `
              linear-gradient(
                45deg,
                transparent 40%,
                rgba(255,255,255,${0.4 * convexStrength}) 50%,
                transparent 60%
              )
            `,
            backgroundSize: '200% 200%',
            backgroundPosition: 'right bottom',
          }}
        />
      </div>
    </div>
  );
});

ConvexGlassCanvas.displayName = 'ConvexGlassCanvas';

export default ConvexGlassCanvas;