import React, { useState } from "react";
import TeamImage1 from './images/TeamImage1.png';
export default function Team() {
  const [collapse, setCollapse] = useState(true);  
  const [showImage, setShowImage] = useState(false);
  const handleClick = () => {
    setCollapse(!collapse);
  };
  const [expandedDivId] = useState(null);

  return (
    <div
      className="
    w-full 
    max-w-[97%] 
    mx-auto 
    h-auto 
    items-center 
    bg-white 
    dark:bg-gray-600 
    dark:text-white 
    rounded-lg 
    overflow-hidden 
    shadow-2xl 
    hover:shadow-lg 
    dark:hover:shadow-slate-50 
    ui-widget-content 
    content-center
    p-2 
    sm:p-4 
    flex 
    flex-col 
    sm:flex-row 
    space-y-2 
    sm:space-y-0 
    sm:space-x-4
    sm:items-center" onClick={handleClick}>
      <div className="justify-items-start ui-widget-content content-center align-middle"  
          onMouseEnter={() => setShowImage(true)}
          onMouseLeave={() => setShowImage(false)}>        
           {( <img 
            className="w-48 h-auto"
            src={TeamImage1}
            alt="Team"
          /> )}
          <b className="text-left text-nowrap">Team</b> 
      </div>
      <div
          className={`dark:bg-gray-600 dark:text-white border-none w-${
            expandedDivId === "div1" ? "full" : "3/4"
          }  ${collapse ? "collapsed" : ""}`}
        >     
      <div className="dark:bg-gray-600 dark:text-white">         
      <div className="h-7 w-auto">&nbsp;</div> 
     <div className="text-justify p-0.5 text-xs">
        {/* <div><h2> To drive away human fears about AI, we know how to tame AI and its by-products, and can have full control over AI at all times/any time, including control over Robotics. <br/>
          This Company has been founded on this principle.</h2><br/>
          -Mr. Muthukumaran Azhagesan, Founder/CEO, Damn it Tame it AI<br/>
          Software Architect, AI/ML Practitioner, Proven Software Developer[Java, Web,  iOS/Swift], SAP Consultant, Inventor<br/>
        </div> */}
          <b>Leadership:&nbsp;&nbsp;&nbsp;</b>Mr. Muthukumaran Azhagesan a.k.a. Kumar Azhagesan/ Kumar ALGates/ Kumar Allen, Founder/CEO/CTO <br/>
          <br></br>
          <p>Discussions on going , i.e. team formation in progress.</p>    
       
        <br/>
        <p><b>Credits:</b><br/>        
        Open AI ChatGPT<br/>
        Open AI DALLE.3<br/>
        Google Gemini<br/>
        Google's MusicLM<br/>
        A fantastic LLM for music(confidential as of now ;-) )<br/>
        Facebook/Meta's MusicGen<br/>    
        Digital LED Music Visualizer architecture design and development by Muthukumaran Azhagesan<br/>    
        Music player base by Pramit Marattha
        </p>
        <br/>
        <p>
          <b>Meet the team:&nbsp;&nbsp;&nbsp;</b>A global team of talented software engineers who teamed up based on proof of working in real-time projects such as hackathons. Onboarding in progress. Feel free to browse an array of our AI/ML projects listed in our home page.
        </p>{" "}       
        <br /> 
      </div>
      <div><b>Join the team:&nbsp;&nbsp;&nbsp;</b>
      <p> Need experienced programmers with real time experience in python, R, overall data science, associated with Machine learning projects. <br/>
          Prefer who have proven experience leveraging regressions, data cleaning and labeling.
          Quick learners but committed, welcome as well. </p>
      </div>
      <br/>
    </div>
    </div>
    </div>
  );
}