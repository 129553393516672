import React, { useState } from "react";
import lotteryPredictionLogo from './images/lottery_prediction.png';

export default function LotteryPrediction() {
  const [collapse, setCollapse] = useState(true);  
  const [showImage, setShowImage] = useState(false);
  const handleClick = () => {
    setCollapse(!collapse);
  };
  const [expandedDivId] = useState(null);

  return (
    <div
      className="
    w-full 
    max-w-[97%] 
    mx-auto 
    h-auto 
    items-center 
    bg-white 
    dark:bg-gray-600 
    dark:text-white 
    rounded-lg 
    overflow-hidden 
    shadow-2xl 
    hover:shadow-lg 
    dark:hover:shadow-slate-50 
    ui-widget-content 
    content-center
    p-2 
    sm:p-4 
    flex 
    flex-col 
    sm:flex-row 
    space-y-2 
    sm:space-y-0 
    sm:space-x-4
    sm:items-center" onClick={handleClick}>
      <div className="justify-items-start ui-widget-content content-center align-middle"  
          onMouseEnter={() => setShowImage(true)}
          onMouseLeave={() => setShowImage(false)}>        
           {( <img 
            className="w-48 h-auto"
            src={lotteryPredictionLogo}
            alt="Lottery prediction"
          /> )}
          <b className="text-left text-nowrap">Lottery Prediction</b> 
      </div>
      <div
          className={`dark:bg-gray-600 dark:text-white border-none w-${
            expandedDivId === "div1" ? "full" : "3/4"
          }  ${collapse ? "collapsed" : ""}`}
        >
      <div className="dark:bg-gray-600 dark:text-white">
      <div className="h-7 w-auto">&nbsp;</div>
     <div className="text-justify p-0.5 text-xs">
      <br />
      <p>
        <b>Objective:&nbsp;&nbsp;&nbsp;</b>Predict lottery numbers.{" "}
      </p>
      <br />
        <p>
          <b>Presentation on YouTube:</b><a href="https://youtu.be/DKPIjz3yF74">Link to Predict lottery numbers(Experiment)</a>
        </p>{" "}
        <br />
        <p>
          <b>About the project:&nbsp;&nbsp;&nbsp;</b>My initial AI and Machine Learning project
          using R, many R packages, and by leveraging regression algorithms
        </p>{" "}
        <br />
        <p>
          <b>Concept, Programming and Integration:&nbsp;&nbsp;&nbsp;</b> Muthukumaran Azhagesan,
          Kumar.ALGate@gmail.com
        </p>{" "}
        <br />
        <p>
          <b>Thanks:&nbsp;&nbsp;&nbsp;</b> to mentor Data Scientist Reyhaneh Pahlevan, also to
          Data Scientist Arshu Maskey, and Data Scientist Danny Ma
        </p>{" "}
        <br />
        <p>
          <b>Certificate:&nbsp;&nbsp;&nbsp;</b> can be shown by <a href="https://www.google.com/url?q=https%3A%2F%2Flablab.ai%2Fevent%2Fai-startup-hackathon%2Fyoutube-navigator%2Fyoutube-navigator&sa=D&sntz=1&usg=AOvVaw3iyFJWCmuCQbQBKlwYrXvEhttps://sites.google.com/view/autoshieldsllc/a-i-machine-learning?authuser=0">clicking here.</a>{" "}
        </p>
      </div>
    
    </div>
    </div>    
    </div>
  );
}