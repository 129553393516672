import React, { useState } from "react";
import youTube_Navigator from './images/YouTube_Navigator.png';

export default function YouTubeNavigator() {
  const [collapse, setCollapse] = useState(true);  
  const [showImage, setShowImage] = useState(false);
  const handleClick = () => {
    setCollapse(!collapse);
  };
  const [expandedDivId] = useState(null);

  return (
    <div
      className="
    w-full 
    max-w-[97%] 
    mx-auto 
    h-auto 
    items-center 
    bg-white 
    dark:bg-gray-600 
    dark:text-white 
    rounded-lg 
    overflow-hidden 
    shadow-2xl 
    hover:shadow-lg 
    dark:hover:shadow-slate-50 
    ui-widget-content 
    content-center
    p-2 
    sm:p-4 
    flex 
    flex-col 
    sm:flex-row 
    space-y-2 
    sm:space-y-0 
    sm:space-x-4
    sm:items-center" onClick={handleClick}>
      <div className="justify-items-start ui-widget-content content-center align-middle"  
          onMouseEnter={() => setShowImage(true)}
          onMouseLeave={() => setShowImage(false)}>        
           {( <img 
            className="w-48 h-auto"
            src={youTube_Navigator}
            alt="YouTube Navigator"
          /> )}
          <b className="text-left text-nowrap">YouTube Navigator</b> 
      </div>
      <div
          className={`dark:bg-gray-600 dark:text-white border-none w-${
            expandedDivId === "div1" ? "full" : "3/4"
          }  ${collapse ? "collapsed" : ""}`}
        >     
      <div className="dark:bg-gray-600 dark:text-white">
      <div className="h-7 w-auto">&nbsp;</div>
     <div className="text-justify p-0.5 text-xs">
        <br /> <div className="mx-auto">with OpenAI's ChatGPT API, Django, Python, chrome extension, and ReactJS</div>
        <br />
        <p>
          <b>Objective:</b>&nbsp;&nbsp;&nbsp;Provide summaries in the style the user needs, as per
          their prompt, save time watching videos.{" "}
        </p>
        <br />
        <p>
          <b>About the project:</b>&nbsp;&nbsp;&nbsp;A cool project that our global team worked
          on, and released during the time ChatGPT kept us in awe a few months
          back in 2023. This is a chrome plugin core idea by Demian Netliukh.
          It's a great team effort. Got appreciation from lablab.ai founding
          team
        </p>
        <br />
        <p>
          <b>Functionalities:&nbsp;&nbsp;&nbsp;</b> User installs our chrome plugin extension, and logs in with their google.com account same as their youtube account. And enters their desired prompt of the detail that they need from the Youtube video. Within 
          seconds, they are provided with the summary tailored to their prompt. One of the first apps/extensions demonstrated using chatGPT!{" "}
        </p>
        <br />
        <b>Demo:&nbsp;&nbsp;&nbsp;</b> can be seen by <a href="https://www.google.com/url?q=https%3A%2F%2Flablab.ai%2Fevent%2Fai-startup-hackathon%2Fyoutube-navigator%2Fyoutube-navigator&sa=D&sntz=1&usg=AOvVaw3iyFJWCmuCQbQBKlwYrXvEhttps://sites.google.com/view/autoshieldsllc/a-i-machine-learning?authuser=0">clicking here</a>.
      </div>
    </div>
    </div>
    </div>
  );
}