import React, { useState, useEffect } from 'react';

const GradualTextHide = () => {
  const [displayText, setDisplayText] = useState('damnit! tame it ai');
  const [isHidden, setIsHidden] = useState(false);
  
  useEffect(() => {
    // Start hiding "Hello!" after 6 seconds
    const timer = setTimeout(() => {
      setIsHidden(true);
    }, 6000); // 6 seconds initial display

    // Cleanup function to clear timers
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="website-title flex text-3xl font-bold mb-5 z-0 dark:bg-slate-500 h-auto">
      {isHidden ? 'tame it! ai' : displayText}
    </div>
  );
};

export default GradualTextHide;